import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Caption, DecsText } from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface AppInputFieldProps {
  label?: string;
  subTitle?: string;
  name: string;
  tooltip?: any;
  divStyle?: any;
  labelStyle?: React.CSSProperties;
  charactersLimit?: number;
  sendValuesToParent?: (values: any) => void | undefined;
  children?: React.ReactNode;
  [otherProps: string]: any;
  requiredSign?: boolean;
  toLowerCase?: boolean;
  showError?: boolean;
  customClassNameInput?: string;
  optionalText?: string;
  className?: string;
  onChangeCallbackFn?: (value: any) => void;
  maxLength?: number;
}

interface StyledProps {
  isError?: any;
}

const AppFormField: React.FC<AppInputFieldProps> = ({
  label,
  name,
  tooltip,
  divStyle,
  subTitle,
  labelStyle,
  sendValuesToParent,
  children,
  charactersLimit,
  requiredSign,
  toLowerCase = false,
  optionalText,
  showError = true,
  customClassNameInput = "",
  className,
  onChangeCallbackFn,
  maxLength,
  ...otherProps
}) => {
  const {
    setFieldTouched,
    errors,
    touched,
    setFieldValue,
    values,
  } = useFormikContext<any>();

  useEffect(() => {
    if (sendValuesToParent) {
      sendValuesToParent(values);
      onChangeCallbackFn && onChangeCallbackFn(values);
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Div style={divStyle}>
      <div className="textLabelOptional">
        <Label style={labelStyle}>
          {requiredSign ? (
            <Fragment>
              {label}
              <sup className="starSuperScript"> *</sup>
            </Fragment>
          ) : (
            label
          )}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </Label>
        {optionalText && (
          <span style={{ color: "rgba(126, 131, 146, 1)", marginLeft: "5px" }}>
            {optionalText}
          </span>
        )}
      </div>
      {subTitle && <DecsText>{subTitle}</DecsText>}

      <Input
        onBlur={() => setFieldTouched(name)}
        onChange={(e: any) => setFieldValue(name, e.target.value)}
        value={
          toLowerCase
            ? getIn(values, name).toLocaleLowerCase()
            : getIn(values, name) || ""
        }
        style={{
          border:
            getIn(errors, name) && getIn(touched, name)
              ? `1px solid ${theme.palette.default.error}`
              : `1px solid lightgray`,
            }}
            className={customClassNameInput}
            maxLength={charactersLimit && charactersLimit}
            {...otherProps}
      />

      {charactersLimit && charactersLimit !== 0 && (
        <Caption>
          {" "}
          Characters: {getIn(values, name)?.length || 0}/{charactersLimit}
        </Caption>
      )}
      <AppErrorMessage
        error={getIn(errors, name)}
        visible={showError && getIn(touched, name)}
      />
    </Div>
  );
};

export default AppFormField;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-bottom: 0.5rem;
    &.custom-input {
      input {
        margin: 0.7rem 0;
      }
    }
  }
  .textLabelOptional {
    display: flex;
    gap: 0.5rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
    sup {
      color: ${theme.palette.default.error};
    }
    .starSuperScript {
      top: 0;
      font-size: 16px;
    }
  }
`;
const Input = styled.input<StyledProps>`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 1.3rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
    margin: 6px 0;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
  &:disabled {
    /* border: 2px solid ${theme.palette.default.border} !important; */
    color: ${theme.palette.default.darkGrey};
    opacity: 0.7;
  }
`;
